import { Flex, FlexProps } from "@chakra-ui/react";
import { NavListTextH } from "@/components/Nav/NavListText";

interface IBlockPanelTitleMeta extends FlexProps {
  useHeight?: boolean;
}

export const BlockPanelTitleMeta = ({
  useHeight = true,
  children,
}: IBlockPanelTitleMeta) => {
  return (
    <Flex
      align="center"
      textStyle="hMeta"
      color="blackAlpha.600"
      pl="1px"
      h={useHeight ? NavListTextH : "auto"}
    >
      {children}
    </Flex>
  );
};
