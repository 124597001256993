import { Flex, FlexProps, Spinner } from "@chakra-ui/react";

export default function HeaderSpinner({
  pt = { lg: "w8" },
  pb = { base: "w8", lg: "w8" },
}: FlexProps) {
  return (
    <Flex
      w="full"
      pt={pt}
      pb={pb}
      h={{ base: "calc(100vh - 64px)", lg: "auto" }}
      justifyContent="center"
      alignItems="center"
      // {...props}
    >
      <Spinner
        thickness="3px"
        speed="0.65s"
        emptyColor="thiel.200"
        color="uranianblue.900"
        size="lg"
      />
    </Flex>
  );
}
