import Link from "next/link";
import { Flex, Box, Icon } from "@chakra-ui/react";
import { MdArrowBackIos } from "react-icons/md";

interface IBlockPanelTitleMetaLink {
  link: string;
  label: string;
}

export const BlockPanelTitleMetaLink = ({
  link,
  label,
}: IBlockPanelTitleMetaLink) => {
  return (
    <Link href={link} passHref>
      <Flex as="a" alignItems="center" color="blackAlpha.600" ml="-3px">
        <Icon as={MdArrowBackIos} boxSize="0.9em" />
        <Box>{label}</Box>
      </Flex>
    </Link>
  );
};
