import { Box, BoxProps } from "@chakra-ui/react";
import { blockW } from "@/theme/layerStyles";

/* `BlockContent` is used within within `LayoutMain` as part of the main Portal "aside" layout. Closely related to BlockContainer, which is used mostly for Quiz and non-signed-in pages. */

interface IBlockContent extends BoxProps {
  useAsideStyle?: boolean;
}

export const BlockContent = ({
  maxW = blockW,
  useAsideStyle = true,
  ...props
}: IBlockContent) => {
  return (
    <Box
      maxW={maxW}
      mx={useAsideStyle ? 0 : "auto"}
      layerStyle={useAsideStyle ? "underNav" : "underNav"}
      px={useAsideStyle ? "insetLg" : "inset"}
      pb="w9"
      {...props}
    >
      {props.children}
    </Box>
  );
};
