import { Container, ContainerProps } from "@chakra-ui/react";

/* `BlockContainer` is used mostly for Quiz and non-signed-in pages. */

interface IBlockContainer extends ContainerProps {
  isWithinLayoutProtected?: boolean;
}

export const BlockContainer = ({
  maxW = "container.sm",
  isWithinLayoutProtected,
  children,
  ...props
}: IBlockContainer) => {
  return (
    <Container
      maxW={maxW}
      px="inset"
      // LayoutDefault uses `spaceUnderNav`
      // LayoutProtected sets `underNav` on `Navside` and `BlockContent` (within `LayoutMain`)
      pt={isWithinLayoutProtected ? 0 : "w5"}
      pb="w9"
      {...props}
    >
      {children}
    </Container>
  );
};
