import React, { useEffect, useState } from "react";
import Head from "next/head";
import { Box, Divider, Text } from "@chakra-ui/react";
import LayoutProtected from "@/components/Layout/LayoutProtected";
import {
  BlockContent,
  BlockPanel,
  BlockPanelTitle,
  BlockMessageNoData,
} from "@/components/Block";
import HeaderSpinner from "@/components/HeaderSpinner";
import { Todo } from "@/components/PageTodo";
import { getAllTodos } from "@/lib/api";
import { ITodo } from "@/types";

/* The Portal index page is the Todos: this show immediately once a user has logged in. */

export default function TodosPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [todos, setTodos] = useState<Array<ITodo>>([]);
  const noMessages = todos?.length === 0;

  useEffect(() => {
    const fetchTodos = async () => {
      setIsLoading(true);
      const res = await getAllTodos();
      const todos = !res.error ? res.json.todos : [];
      setTodos(todos);
      setIsLoading(false);
    };
    fetchTodos();
  }, []);

  // console.log("todos", todos);

  return (
    <LayoutProtected>
      <Head>
        <title>Your Treatment Todos | Cleared Patient Portal</title>
      </Head>

      <BlockContent id="login-container-for-browser-checks">
        <BlockPanel showPb>
          <BlockPanelTitle
            title="Todos"
            heading="Your current treatment progress"
            lede="If you have any questions about your treatment plan, please email support@getcleared.com"
          />
          {/* different layout set-up compared to `/subscriptions` and /atk` */}
          {isLoading && (
            <Box w="full">
              <Divider opacity={1} />
              <HeaderSpinner pt="w6" />
            </Box>
          )}

          {!isLoading && noMessages && (
            <Box w="full">
              <Divider opacity={1} />
              <BlockMessageNoData
                pt="w3"
                message="You don't have any to-dos to act on."
              />
            </Box>
          )}

          {!isLoading && !noMessages && (
            <>
              {todos.length && (
                // <>
                //   {todos.map((todo, index) => (
                //     <Todo key={`${index}-${todo.id}`} todo={todo} />
                //   ))}
                // </>
                <Box py={{ base: 3 }}>
                  <Text color="blackAlpha.600">
                    Todo&apos;s have been disabled
                  </Text>
                </Box>
              )}
            </>
          )}
        </BlockPanel>
      </BlockContent>
    </LayoutProtected>
  );
}
