import * as React from "react";
import { Box } from "@chakra-ui/react";
import { BlockLoading } from "@/components/Block";
import { blockW } from "@/theme/layerStyles";

export interface BlockPanelProps {
  bg?: string;
  showPy?: boolean;
  showPb?: boolean;
  children: React.ReactNode;
}

export const BlockPanel = ({
  bg = "white",
  showPy,
  showPb,
  children,
}: BlockPanelProps) => {
  return (
    <Box
      pos="relative"
      bg={bg}
      py={showPy && "inset"}
      pb={showPb && "inset"}
      minH={{ base: "100vh", lg: "45vh", xxl: "35vh" }}
    >
      {/* <BlockLoading maxH={{ base: "50vh", xl: "25vh" }} /> */}

      {children}
    </Box>
  );
};

export default BlockPanel;
