import { Box, Text } from "@chakra-ui/react";
import DefaultVStack from "@/components/VStack";
import { BlockPanelTitleMeta } from "./BlockPanelTitleMeta";

interface IBlockPanelTitle {
  title: string | React.ReactNode;
  heading: string | React.ReactNode;
  lede?: string | React.ReactNode;
  button?: React.ReactNode;
  [x: string]: any;
}

export const BlockPanelTitle = ({
  title,
  heading,
  button,
  lede,
  ...props
}: IBlockPanelTitle) => {
  return (
    <Box pb={lede === undefined ? 0 : "w5"} {...props}>
      <BlockPanelTitleMeta>{title}</BlockPanelTitleMeta>
      <DefaultVStack spacing="w2" pt={1}>
        <Text as="h1" textStyle="h3xl" pos="relative">
          {heading}

          {/* maintain the block height whether button or not */}
          {/* usually expects <Button variant="minor" size="sm"> */}
          {/* could possibly set this with clickhandler prop? */}
          {button !== undefined && (
            <Box
              pos="absolute"
              right="2px"
              top="50%"
              transform="translateY(-50%)"
            >
              {button}
            </Box>
          )}
        </Text>
        {/* lede is optional so we can reuse this component on both `TodoMessage` and `TodoMessagePage` */}
        {lede && (
          <Text textStyle="textBody" w={{ lg: "83%" }}>
            {lede}
          </Text>
        )}
      </DefaultVStack>
    </Box>
  );
};
