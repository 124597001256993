import { Box, Flex, FlexProps, Icon } from "@chakra-ui/react";
import { MdOutlineError } from "react-icons/md";

export const BlockMessageNoData = ({
  message,
  ...props
}: { message: string } & FlexProps) => (
  <Flex textStyle="textBody" color="blackAlpha.600" align="center" {...props}>
    <Icon as={MdOutlineError} mr="2" mt="-1px" />
    <Box>{message}</Box>
  </Flex>
);
